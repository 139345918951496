import { AxiosResponse } from 'axios';
import axiosClient from './baseApi';
import LoginRequest from './LoginRequest';
import LoginResponse from './LoginResponse';

const authPath = {
  login: 'auth/login',
  logout: 'logout',
  subscribe: 'subscribe',
  forgotPassword: 'users/forget-password',

  resetPassword: 'users/reset-password',
  updateResetPassword: 'users/update-reset-password',
};

export const login = (data: LoginRequest) => {
  return axiosClient.post<AxiosResponse<LoginResponse>>(authPath.login, data);
};

export const logout = (): Promise<AxiosResponse<any, any>> => {
  return axiosClient.get(authPath.logout);
};

export const forgotPassword = (
  email: string,
): Promise<AxiosResponse<any, any>> => {
  const params = {
    email: email,
  };

  return axiosClient.get(authPath.forgotPassword, { params });
};

// [GET] Reset password
export const resetPassword = (
  token: string,
  email: string,
): Promise<AxiosResponse<any, any>> => {
  const params = {
    email: email,
  };
  return axiosClient.get(`${authPath.resetPassword}/${token}`, { params });
};

// [POST] Update reset password
export const updateResetPassword = (
  password: string,
  email: string,
  token: string,
): Promise<AxiosResponse<any, any>> => {
  const params = {
    email: email,
    password: password,
  };

  return axiosClient.post(`${authPath.updateResetPassword}/${token}`, params);
};
