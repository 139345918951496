import { AxiosResponse } from 'axios';
import ServerResponse from '../models/ServerResponse';
import axiosClient, { axiosFromData } from './baseApi';
import { TResponseData, TResponseMetadata } from '../types/Types';

export const usersQueryKey: any = {
  getAllGetById: 'getByUsers',
  getAllGetAll: 'getAllUsers',
  getFilterGetAllKey: 'getFilterGetAllKey',
};

export const GetById = async (id: any) => {
  try {
    const res = await axiosClient.get<ServerResponse<any>>('/profile/one/', id);
    return await Promise.resolve(res.data);
  } catch (err) {
    return await Promise.reject(err);
  }
};

export const getCurrentUser = async () => {
  try {
    const res = await axiosClient.get<AxiosResponse<any>>('/profile/current');
    return await Promise.resolve(res.data);
  } catch (err) {
    return await Promise.reject(err);
  }
};

export const updateProfile = async (data: any) => {
  try {
    const res: Promise<AxiosResponse<TResponseData, TResponseMetadata>> =
      axiosClient.put<ServerResponse<any>>(`/profile/update`, data);
    return await Promise.resolve(res);
  } catch (err) {
    return await Promise.reject(err);
  }
};

export const updateProfileForm = async (data: any) => {
  try {
    const res: any = await axiosFromData.post<AxiosResponse<any>>(
      `/profile/update`,
      data,
    );
    return await Promise.resolve(res.data);
  } catch (err) {
    return await Promise.reject(err);
  }
};

export const getUser = async (token: any, userId: any) => {
  try {
    const res = await axiosClient.get<ServerResponse<any>>(
      `profile/one/${userId}`,
      { headers: { Authorization: `Bearer ${token}` } },
    );
    return await Promise.resolve(res?.data?.data);
  } catch (err) {
    return await Promise.reject(err);
  }
};

export const getAll = async () => {
  try {
    const res = await axiosClient.get<ServerResponse<any>>('/users/all');
    return await Promise.resolve(res.data);
  } catch (err) {
    return await Promise.reject(err);
  }
};

export const getByEmail = async (email: string) => {
  try {
    const x = await axiosClient.get<ServerResponse<any>>(`/user/${email}/get`);
    return await Promise.resolve(x.data.data);
  } catch (err) {
    return await Promise.reject(err);
  }
};

export const getUserById = async (userId: any) => {
  try {
    const res = await axiosClient.get<ServerResponse<any>>(
      `profile/one/${userId}`,
    );
    return await Promise.resolve(res.data.data);
  } catch (err) {
    return await Promise.reject(err);
  }
};

export const currentUser = async () => {
  try {
    const res = await axiosClient.get<ServerResponse<any>>(`profile/current`);
    return await Promise.resolve(res.data.data);
  } catch (err) {
    return await Promise.reject(err);
  }
};

export const currentUser2 = async () => {
  try {
    const res = await axiosClient.get<ServerResponse<any>>(`/user/info`);
    return await Promise.resolve(res.data.data);
  } catch (err) {
    return await Promise.reject(err);
  }
};

export const getAllFilter = async () => {
  try {
    const res = await axiosClient.get<AxiosResponse<any>>('/users/getFilter');
    return await Promise.resolve(res.data);
  } catch (err) {
    return await Promise.reject(err);
  }
};

export const tokenActivation = async (token: string) => {
  try {
    const res = await axiosClient.get<AxiosResponse<any>>(
      `/users/activation/${token}`,
    );
    return await Promise.resolve(res?.data);
  } catch (err) {
    return await Promise.reject(err);
  }
};

export const updateActivation = async (data: any) => {
  try {
    return await axiosClient.post<AxiosResponse<any>>(
      '/users/activation/update',
      data,
    );
  } catch (err) {
    return await Promise.reject(err);
  }
};
